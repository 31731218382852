import React, {Component} from "react"
import SEO from "utils/Seo"
import Layout from "../components/Layout/Layout"
import styled from "styled-components"
import {Link} from "gatsby"
import {media} from "utils/Media"
import TopBar from "../components/shared/TopBar";
import SnowFlakes from "../components/shared/SnowFlakes";

const Content = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 2rem;
`
const BoxWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    align-items: stretch;
    padding: 2rem 0;
`

const Box = styled.div`
    filter: drop-shadow(0px 0px 12px rgba(0, 49, 153, 0.2));
    background: white;
    padding: 1rem;
    width: 100%;
    margin: 0.5rem auto;

    @media ${media.sm} {
        margin: 1rem;
        width: calc(50% - 2rem);
    }

    @media ${media.md} {
        padding: 2rem;
    }

    h3 {
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.navy};
        font-size: 24px;
        margin-bottom: 1rem;
        text-align: center;

        @media ${media.sm} {
            font-size: 30px;
            line-height: 35px;
        }

    }

    p {
        @media ${media.lg} {
             min-height: 85px;
        }
    }
`

class Index extends Component {
    constructor(props) {
        super(props);

        this.pageData = {
            pageName: "enrichment-materials",
            siteName: "frozeneducation",
            country: "uk",
            region: "emea"
        }
    }

    componentDidMount() {
        // if (typeof window !== 'undefined' && typeof window.utag !== 'undefined') {
        //     // Tealium
        //     window.utag.view(this.pageData)
        // }
    }

    render() {

        return (
            <Layout pageName="enrichment-materials" enrichmentHeader subMenuHidden={true}>
                <SEO slug="enrichment-materials" title="Enrichment Materials" description=""/>
                <Content className="enrichment">
                    <TopBar logo={false} title="Beyond Frozen: Education Resources"/>
                    <SnowFlakes>
                        {/*<ScrollAnimation animateOnce={true} delay={0} animateIn="fadeIn">*/}
                            <BoxWrapper>
                                <Box>
                                    <h3>Beyond the Story</h3>
                                    <p>This rich educational experience is designed for students, fans, teachers, and guests to engage more deeply with FROZEN the Musical.</p>
                                    <p>Explore the production’s background and journey from page to stage. Teachers, be sure to check out the accompanying lesson plans available in certain sections.</p>
                                    <Link to="/beyond-the-story/inspiration/nordic-influences" className="btn btn--new d-flex mx-auto text-uppercase btn--color-white my-3"><span>Inspiration</span></Link>
                                    <Link to="/beyond-the-story/design/costumes" className="btn btn--new d-flex mx-auto text-uppercase btn--color-white my-3e"><span>Design</span></Link>
                                    <Link to="/beyond-the-story/adaptation/characters" className="btn btn--new d-flex mx-auto text-uppercase btn--color-white my-3"><span>Adaptation</span></Link>
                                </Box>
                                <Box>
                                    <h3>Teaching Resources</h3>
                                    <p>These creative and adaptable resources will bring learning to life in your classroom using the theme and characters in the story.</p>
                                    <p>Performance based workshops are also available, which can be booked to enhance your groups’ theatre visit.</p>
                                    <Link to="/teaching-resources/lesson-plans" className="btn btn--new d-flex mx-auto text-uppercase btn--color-white my-3"><span>Lesson plans</span></Link>
                                    <Link to="/teaching-resources/wellbeing-resources" className="btn btn--new d-flex mx-auto text-uppercase btn--color-white my-3"><span>Wellbeing Resources</span></Link>
                                    <Link to="/teaching-resources/workshops" className="btn btn--new d-flex mx-auto text-uppercase btn--color-white my-3"><span>Workshops</span></Link>
                                </Box>
                            </BoxWrapper>
                        {/*</ScrollAnimation>*/}
                    </SnowFlakes>
                </Content>
            </Layout>
        )
    }
}

export default Index
