import React, {Component} from "react"
import styled, {css} from 'styled-components'
import {graphql, Link, StaticQuery} from 'gatsby'
import {media} from "utils/Media"
import {GatsbyImage, getImage} from "gatsby-plugin-image"

const Wrapper = styled.div`
    position: relative;
    
    @media ${media.sm} {
    }
    
    .content {
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
        text-align: center;
    }
    
    h1 {
        margin: 0;
        font-family: ${props => props.theme.font.family.base};
        /* color: ${props => props.theme.colors.navy}; */
        color: #ffffff;
        /* text-shadow: 2px 2px 2px white; */

        mix-blend-mode: luminosity;
        font-weight: 700;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center; 
        text-transform: uppercase;  
        font-size: 2rem;
        
        @media ${media.sm} {
            font-size: 1.5rem;
        }
        
        @media ${media.md} {    
            font-size: 2rem;
        }  
   
    }
    
    .logo {
        max-width: 485px;
    }
    
`

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                FallbackXs: file(relativePath: { eq: "header-edu-home-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                FallbackSm: file(relativePath: { eq: "header-edu-home-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
              
                FallbackXl: file(relativePath: { eq: "header-edu-home-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                GalleryPageXs: file(relativePath: { eq: "VideoHub/header-sub-GALLERY-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                GalleryPageSm: file(relativePath: { eq: "VideoHub/header-sub-GALLERY-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
              
                GalleryPageXl: file(relativePath: { eq: "VideoHub/header-sub-GALLERY-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                logo: file(relativePath: { eq: "Logo_lrg.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 800, quality: 100, placeholder: NONE
                            formats: [AUTO, WEBP, AVIF])
                    }
                }
            }
		`}
        render={data => (
            <TopBar logo={props.logo} pageName={props.pageName} title={props.title} data={data}/>
        )}
    />
)

class TopBar extends Component {
    render() {
        return (
            <Wrapper>
                <GatsbyImage placeholder="none"
                             image={getImage(this.props.data.FallbackXs)}

                             alt=""
                             title=""
                             className="img-fluid d-block d-sm-none"
                />
                <GatsbyImage placeholder="none"
                             image={getImage(this.props.data.FallbackSm)}

                             alt=""
                             title=""
                             className="img-fluid d-none d-sm-block d-lg-none"
                />
                <GatsbyImage placeholder="none"
                             image={getImage(this.props.data.FallbackXl)}

                             alt=""
                             title=""
                             className="img-fluid d-none d-lg-block"
                />

                <div className="content">
                    {this.props.logo &&
                    <GatsbyImage placeholder="none" image={getImage(this.props.data.logo)} alt="FROZEN the Musical"
                                 title="FROZEN the Musical"
                                 className="img-fluid logo mx-auto"/>
                    }
                    <h1 className={`text-center ${this.props.logo && 'font--smaller'}`}>{this.props.title}</h1>
                </div>
            </Wrapper>
        );
    }
}

export default Query;